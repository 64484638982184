<template>
  <WStatsWidget
    ref="widget"
    :title="title"
    :height="height"
    :subtitle="displayedSubtitle"
    :loading="loading"
    :empty="isEmpty"
    :linkTo="linkTo"
    :cols="cols"
  >
    <template #content>
      <v-tooltip :bottom="true" color="white" content-class="voter-volume-per-source-tooltip-custom">
        <template v-slot:activator="{ on, attrs }">
          <div
            class="voter-volume-per-source-div-global-content"
            v-bind="attrs"
            v-on="on"
            >
            <div ref="voter-volume-per-source-div-chart">
              <Chart :options="chartOptions" ref="chart" />
            </div>
            <div class="d-flex flex-column flex-row align-center voter-volume-per-source-div-middle-chart">
              <span :class="`c-primary lh-34 ${getFontSize} font-weight-bold`">
                {{ countToCompact(nbReview) }}
              </span>
              <w-color-sign
                v-if="showEvolution"
                :decimals="0"
                :value="evolution"
                :suffix="' %'"
              />
            </div>
          </div>
        </template>
        <div>
          <table>
            <tbody>
              <tr
                v-for="countBySource in countsBySourceLegend"
                :key="countBySource[0]"
              >
                <td style="padding: 0 8px">
                  <div class="d-flex align-center ga-1">
                    <v-avatar
                      :color="countBySource.color"
                      size="16"
                    ></v-avatar>
                    <w-source-icon
                      class="c-grey"
                      iconSize="f-14"
                      :source="countBySource.source"
                      :autoColor="true"
                    />
                    <span class="c-primary lh-28 f-14 font-weight-bold">{{ countBySource.label }}</span>
                  </div>
                </td>
                <td><span class="c-primary lh-28 f-14">{{ countBySource.nbReview }}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-tooltip>
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart } from "highcharts-vue"
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import WizIcon from '@theme/components/WIcon'
import { COLORS_BY_SOURCE, SOURCE_MAPPING } from '@statistics/shared/voters_sources_constants'

export default {
  name: 'VoterVolumePerSourceWidget',
  components: {
    Chart,
    WizIcon
   },
  mixins: [
    WidgetMixin
  ],
  data(){
    return {
      loading: true,
      nbReview: 0,
      nbReviewPreviousPeriod: 0,
      countsBySource: []
    }
  },
  props: {
    height: { type: String, default: '200px' },
    cols: { required: false, default: 3 },
    withLink: { required: false, default: false }
  },
  asyncComputed: {
    chartOptions: {
      default: {},
      async get() {
        this.loading = true
        this.nbPrevious = 0;
        this.nbPreviousPeriod = 0;
        this.countsBySource = []

        let datas = [];
        let colors = [];

        const response = await this.$resolve(this.basedRequest)
        const responsePreviousPeriod = await this.$resolve(this.basedRequest.dateBetween(this.sqlComparedDateBegin, this.sqlComparedDateEnd))

        for (const [key, countBySource] of Object.entries(response.data['voters'])) {
          let groupedSource = SOURCE_MAPPING[countBySource['source']] || countBySource['source']
          let notFound = true

          for (let i = 0; i < this.countsBySource.length; i++){
            if (this.countsBySource[i][0] == groupedSource) {
              this.countsBySource[i][1] = this.countsBySource[i][1] + countBySource['countDistinctId']
              notFound = false
            }
          }

          if (notFound) {
            this.countsBySource.push([groupedSource,countBySource['countDistinctId']])
          }
          this.nbReview = this.nbReview + countBySource['countDistinctId']
        }

        for (let i= 0; i < this.countsBySource.length; i++) {
          datas.push(this.countsBySource[i][1]);
          colors.push(this.colorBySource(this.countsBySource[i][0]));
        }

        for (const [key, countBySource] of Object.entries(responsePreviousPeriod.data['voters'])) {
          this.nbReviewPreviousPeriod = this.nbReviewPreviousPeriod + countBySource['countDistinctId']
        }

        this.loading = false

        return {
          chart: {
            type: 'pie',
            height: '140px',
            width: '160'
          },
          xAxis: {
            categories: []
          },
          plotOptions: {
            series: {
              thickness: 7,
              enableMouseTracking: false,
              borderRadius: '50%',
              borderWidth: 2,
              borderColor: 'white',
              dataLabels: {
                  enabled: false
              },
              size: '90%',
              innerSize: '70%',
            }
          },
          exporting: {
            enabled: false
          },
          colors: colors,
          series: [
            {
              data: datas
            }
          ],
          title: {
            text: '',
            align: 'center',
            verticalAlign: 'middle'
          },
          credits: {
            enabled: false
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'hasAccessToAllDashboardPlaces',
      'sqlComparedDateBegin',
      'sqlComparedDateEnd',
      'dashboardFilterRequest'
    ]),
    showEvolution() {
      return this.nbReviewPreviousPeriod !== null && this.nbReview !== null
    },
    evolution() {
      return this.showEvolution ? Math.round(( this.nbReview - this.nbReviewPreviousPeriod ) / this.nbReview * 100 ) : null
    },
    isEmpty() {
      return this.nbReview == 0
    },
    getFontSize() {
      const charNumber = this.countToCompact(this.nbReview).length
      switch (true) {
        case charNumber < 4:
          return "f-26"
        case charNumber < 6:
          return "f-22"
        case true:
          return "f-18"
      }
    },
    countsBySourceLegend() {
      let countsBySourceWizville = []
      let countsBySourceNotWizville = []

      for (let i = this.countsBySource.length - 1; i >= 0; i--) {
        const countBySource = {
          'source': this.countsBySource[i][0],
          'nbReview': this.countsBySource[i][1],
          'color': this.colorBySource(this.countsBySource[i][0]),
          'label': this.$t(`source.${this.countsBySource[i][0]}`)
        }
        if (this.isWizvilleSource(this.countsBySource[i][0])) {
          countsBySourceWizville.push(countBySource)
        } else {
          countsBySourceNotWizville.push(countBySource)
        }
      }
      return countsBySourceWizville.concat(countsBySourceNotWizville)
    },
    linkTo() {
      if (this.withLink) {
        return {
          name: 'Statistics',
          params: { sectionId: 'all', sectionType: 'voterVolume'}
        }
      } else {
        return ''
      }
    },
    basedRequest() {
      return this.dashboardFilterRequest.select({
        voters: [ "source", "COUNT_DISTINCT_id" ]
      }).group([
        "source"
      ]).order([
        "COUNT_DISTINCT_id", "asc"
      ])
    },
    title() {
      return this.$t(`voter_volume_per_source`)
    }
  },
  methods: {
    colorBySource(source) {
      return COLORS_BY_SOURCE[source]
    },
    isWizvilleSource(source) {
      return !['google', 'facebook', 'pages_jaunes', 'trip_advisor', 'ubereats', 'deliveroo', 'skeepers'].includes(source)
    },
    countToCompact(count) {
      if (count < 10000) return new Intl.NumberFormat().format(count)
      if (count < 1000000) return new Intl.NumberFormat().format(Math.floor(count/1000)) + 'K'
      return new Intl.NumberFormat().format(Math.floor(count/1000000)) + "." + (Math.floor(count/10000)%100).toString() + 'M'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .widget-link
    display: contents

  .strong
    font-weight: bold;

  .voter-volume-per-source-tooltip-custom
    border-radius: 5px;
    box-shadow: 2px 2px 6px lightgray;
    opacity: 1;

  .voter-volume-per-source-tooltip-custom::after
    content: ''
    position: absolute
    bottom: 100%
    left: 50%
    transform: translateX(-50%)
    width: 0
    height: 0
    border-left: 10px solid transparent
    border-right: 10px solid transparent
    border-bottom: 10px solid white

  .voter-volume-per-source-div-global-content
    position: relative;
    width: 160px;
    height: 140px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

  .voter-volume-per-source-div-chart
    top: 0px;
    left: 0px;
    position: absolute;
    width: 160px;
    height: 140px

  .voter-volume-per-source-div-middle-chart
    top: 45px;
    left: 0px;
    position: absolute;
    width: 160px;
</style>
