<template>
  <Table
    :categoryId="categoryId"
    :topics="topics"
    :campaign="campaign"
    :loading="!ready"
    mode="products"
    :totalCount="productsTotalCount"
    :items="items"
    v-model="options"
  />
</template>

<script>
  import { mapGetters } from 'vuex'

  import Table from './Table'

  export default {
    name: "ProductsTable",
    props: {
      categoryId: { type: Number, required: false },
      topics: { type: Array, required: false, default: () => [] },
      campaign: { required: false },
      applyNavigationFilters: { required: false, default: true },
      availableLocales: { required: false, default: () => [] }
    },
    components: {
      Table
    },
    data() {
      return {
        loading: true,
        options: {
          page: 1,
          itemsPerPage: 50
        }
      }
    },
    methods: {
      async fetchProducts() {
        if (this.availableLocales) {
          const product_name_field = this.currentCampaignProductStorage !== 'legacy' ?
            'product_name' :
            'product_legacy_name'

          let request = this.dashboardFilterRequest.select({
            voters: [
              { product_ratio_recommendation: { as: 'recommendation' } },
              { avg_score: { as: 'score', mod: 'AVG_ROUND_2' } },
              { COUNT_DISTINCT_id: { as: 'nbReview' } },
              { avg_score_with_minimun_nb_reviews: { as: 'rank', minimum_nb_reviews: this.minimalNumberOfFeedbackForRanking } },
              { product_id: { as: 'productId' } },
              { MAX_product_code: { as: 'productCode' } },
              { [`MAX_${product_name_field}`]: { as: 'productName', available_locales: this.availableLocales } }
            ]
          }).where({
            campaign_id: this.campaign.id,
            avg_score: { 'is_not_null': {} }
          }).group(
            ['product_id']
          ).offset(
            (this.options.page - 1) * this.options.itemsPerPage
          ).limit(
            this.options.itemsPerPage
          ).order([
              [
                {
                  avg_score_with_minimun_nb_reviews: {
                    minimum_nb_reviews: this.minimalNumberOfFeedbackForRanking
                  }
                },
                'desc'
              ],
              [{ avg_score: { as: 'score', mod: 'AVG_ROUND_2' } }, 'desc'],
              ['COUNT_id', 'desc']
            ]
          )

          if (this.applyNavigationFilters) {
            if (this.categoryId == null || this.categoryId == undefined) {
              request = request.where({ product_category_level: 0 })
            } else {
              request = request.where({
                product_category_id: this.categoryId
              })
            }
          }

          const result = (await this.$resolve(request))?.data?.voters || []

          return result
        }
      },
      async fetchVoterAvgTopicsByProductId(productIds) {
        let request = this.dashboardFilterRequest.select({
          voter_avg_topics: [
            { topic_id: { as: 'topicId' } },
            { AVG_avg_topic: { as: 'score' } },
            { MAX_topics_name: { as: 'topicName' } },
            { nb_review: { as: 'nbReview' } }
          ]
        }).where({
          product_id: this.productIds,
          campaign_id: this.campaign.id
        }).order(
          [
            ['topic_id', "desc"],
          ]
        ).group(['product_id', 'topic_id'])

        if (this.applyNavigationFilters) {
          if (this.categoryId == null || this.categoryId == undefined) {
            request = request.where({ product_category_level: 0 })
          } else {
            request = request.where({
              product_category_id: this.categoryId
            })
          }
        }

        const result = (await this.$resolve(request)).data || {}

        return result
      },
      buildGlobalHash(item, rank) {
        return {
          id: item?.productId,
          rank: rank,
          name: item?.productName,
          code: item?.productCode,
          score: parseFloat(item?.score),
          nbReview: item?.nbReview,
          recommendation: parseFloat(item?.recommendation)
        }
      },
      buildTopicsHash(avgProductTopics) {
        let topicsHash = {}

        if (avgProductTopics) {
          Object.keys(avgProductTopics).forEach((topicId) => {
            const avgProductTopic = avgProductTopics[topicId]

            const topicHash = {
              [`topic-${topicId}`]: {
                score: parseFloat(avgProductTopic.score),
                nbReview: avgProductTopic.nbReview
              }
            }
            topicsHash = {...topicsHash, ...topicHash}
          })
        }
        return topicsHash
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
        'currentDashboardProductCampaignById',
        'minimalNumberOfFeedbackForRanking'
      ]),
      currentCampaignProductStorage() {
        return this.currentDashboardProductCampaignById[this.campaign.id]?.productStorage
      },
      ready() {
        return (
          this.items !== undefined &&
          !this.loading &&
          this.productsTotalCount !== undefined
        )
      }
    },
    asyncComputed: {
      items: {
        async get() {
          this.loading = true

          let rank = (this.options.page - 1) * this.options.itemsPerPage + 1

          const products = await(
            this.fetchProducts()
          )

          const productIds = products.map(
            item => item.productId
          )

          const voterAvgTopicsByProductId = await(
            this.fetchVoterAvgTopicsByProductId(productIds)
          )

          const items = products.map((item) => {
            const globalHash = this.buildGlobalHash(
              item,
              item.rank !== -1 ? rank : null
            )

            const avgProductTopics = voterAvgTopicsByProductId?.[item.productId]

            const topicsHash = this.buildTopicsHash(
              avgProductTopics
            )

            rank += 1

            return {
              ...globalHash,
              ...topicsHash
            }
          })

          this.loading = false

          return items
        }
      },
      productsTotalCount: {
        async get() {
          let request = this.dashboardFilterRequest.select({
            voters: [
              { COUNT_DISTINCT_product_id: { as: 'totalCount' }}
            ]
          }).where({
            campaign_id: this.campaign.id,
            avg_score: { 'is_not_null': {} }
          })

          if (this.applyNavigationFilters) {
            if (this.categoryId == null || this.categoryId == undefined) {
              request = request.where({ product_category_level: 0 })
            } else {
              request = request.where({
                product_category_id: this.categoryId
              })
            }
          }

          const result = (await this.$resolve(request)).first()?.totalCount || 0

          return result
        }
      }
    }
  }
</script>

<style lang="stylus">
</style>
