<template>
  <v-form ref="form" v-model="isValid" class="trustville-report d-flex flex-column">
    <v-row class="flex-grow-0">
      <v-col cols="12" sm="10" class="py-0 my-1">
        <v-select
          v-model="trustvilleReport.reason"
          :rules="[$rules.required]"
          :items="trustvilleReportSelectOptions"
          background-color="#eef1f5"
          class="trustville-report-select"
          :placeholder="$t('trustville_report_reason_placeholder')"
          hide-details
          filled
          dense
          attach
        ></v-select>
      </v-col>
    </v-row>

    <v-row class="flex-grow-0" v-if="currentAdmin || currentUserIsAdmin">
      <v-col cols="12" sm="6" class="py-0 my-1">
        <v-checkbox 
          :label="$t('trustville_report_allow_new_feedback')"
          class="trustville-report-checkbox"
          v-model="trustvilleReport.allowNewFeedback"
          :disabled="trustvilleReport.reason === 'mediation'"
          hide-details
          dense
        />
      </v-col>
    </v-row>

    <v-row class="flex-grow-1">
      <v-col class="py-0 mt-1">
        <v-textarea
          v-model="trustvilleReport.comment"
          class="content-textarea f-14"
          persistent-placeholder
          :label="$t('trustville_report_comment_placeholder')"
          background-color="#eef1f5"
          hide-details="auto"
          name="content"
          height="100%"
          rows="0"
          dense
          filled
          no-resize
        />
      </v-col>
    </v-row>
    <v-row class="flex-grow-0" v-if="trustvilleReport.reason === 'mediation'">
      <v-col class="py-0 mt-1">
        <v-tooltip transition="none" left :max-width="'350px'">
          <template #activator="{ on }">
            <div v-on="on">
              <v-icon class="f-14">mdi-comment-alert</v-icon>
              {{ $t('trustville_legal_information') }}
            </div>
          </template>
         
          <span class="f-12">{{ $t('trustville_report_mediation_warning') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row class="flex-grow-0">
      <v-col class="text-right py-0 mb-1 mt-2">
        <v-btn
          class="save"
          @click="sendTrustvilleReport"
          :disabled="!isValid"
          :ripple="false"
          :loading="loading"
          color="secondary"
        >
          <v-icon left dark>mdi-flag</v-icon>
          {{ $t('trustville_report') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import moment from 'moment'

  export default {
    name: "TrustvilleReport",
    props: {
      voter: { required: true }
    },
    computed: {
      ...mapGetters([
        'currentVoter',
        'voterTrustvillePublicState',
        'currentLexicon',
        'currentAdmin',
        'currentUserIsAdmin'
      ]),
      trustvilleDefaultMediationMessage() {
        return this.$t('trustville_default_mediation_message', {  customer: this.currentLexicon.translate('customer', 'customer') })
      },
      trustvilleReportReasons() {
        const reasons = [
          "advertising", "call_legal_action",
          "coarse", "conflict",
          "defamatory", "fake_id",
          "incoherent", "insult",
          "legal_liability", "malicious",
          "no_experience", "non_appropriate",
          "others", "personal_info",
          "reject_asked", "skew_results",
          "spam",  "unintelligible",
          "unrelated"
        ]

        if (this.voterTrustvillePublicState === 'soon_online') {
          reasons.unshift("mediation")
        }

        return reasons
      },
      trustvilleReportSelectOptions() {
        return this.trustvilleReportReasons.map(reason => {
          return {
            text: this.$t(`report_reason_${reason}`),
            value: reason
          }
        })
      }
    },
    data() {
      return {
        trustvilleReport: {
          allowNewFeedback: false,
          reason: null,
          comment: ''
        },
        isValid: false,
        loading: false
      }
    },
    watch: {
      'trustvilleReport.reason'(newVal, oldVal) {
        if (newVal == 'mediation') {
          this.trustvilleReport.comment = this.trustvilleDefaultMediationMessage.replaceAll("\\n", "\r\n")
          this.trustvilleReport.allowNewFeedback = true
        } else if (oldVal == 'mediation') {
          this.trustvilleReport.comment = ''
          this.trustvilleReport.allowNewFeedback = false
        }
      }
    },
    methods: {
      async sendTrustvilleReport() {
        try {
          await this.$api.wizville.trustville.reportVoter(this.voter.uniqueId, this.trustvilleReport)

          this.$store.dispatch('notifySuccess', { message: this.$t('trustville_report_warning_message'), timeout: 5000 })
          this.$store.dispatch('events', { voterUpdate: { voter: { uniqueId: this.voter.uniqueId } } })
        } catch (e) {
          console.error("sendTrustvilleReportError", e)
          this.$store.dispatch('notifyError')
        }
      }
    }
  }
</script>

<style lang='stylus'>
  @import '~@theme/medias.styl'
  .trustville-report
    height: 100%
    padding: 20px 10px 20px 10px
    .content-textarea
      height: 100%
      textarea
        line-height: 1rem
    .content-textarea .v-input__control
      height: 100% !important
    +forBreakpoints(mobile, tablet)
      .content-textarea
        min-height: 200px

    .trustville-report-checkbox
      position: relative
      bottom: 3px

    .trustville-report-checkbox label, .trustville-report-select input, .trustville-report-select .v-select__selection
      font-size: 13px
</style>

