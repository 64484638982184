<template>
  <WStatsWidget
    :cols="12"
    :subtitle="displayedSubtitle"
    :title="title"
    class="evolution-widget"
    contentPadding="0.75em 0.75em 0 0.75em"
    contentWidth="100%"
  >
    <template #title>
      <slot name="title">
        {{ title || $t('widget_evolution_title') }}
      </slot>
    </template>

    <template #options>
      <div class="d-flex align-center">
        <div>
          <slot name="options">
          </slot>
        </div>
        <div>
          <w-drop-down-menu
            data-html2canvas-ignore
            :items="exportOptions"
            icon="mdi-download">
          </w-drop-down-menu>
        </div>
      </div>
    </template>

    <template #content>
      <WTimelineChart
        ref="timelineChart"
        height="300px"
        :quarterOffset="currentUser.quarterOffset"
        :key="dashboardFilterBase64"
        :defaultOptions="defaultOptions"
        :tooltipContent="tooltipContent"
        :yAxisLabelsFormatter="yAxisLabelsFormatter"
        :decimals="decimals"
        :serieColors="serieColors || [$colors.violet]"
        type='areaspline'
      />
    </template>

    <template #footer-left />
    <template #footer-right />
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

  export default {
    name: "EvolutionWidget",
    props: {
      title: { type: String, required: false },
      basedRequest: { required: false },
      campaign: { required: false },
      indicatorAlias: { required: false },
      avgScoreScale: { required: false },
      min: { required: false },
      max: {required: false },
      decimals: { required: false },
      chartZoom: { required: false, default: 2 },
      yAxisLabelsFormatter: {
        type: Function,
        required: false,
        default() {
          return null
        }
      },
      colorType: { type: String, required: false },
      colorLines: {
        required: false
      },
      legendEnabled: {
        type: Boolean,
        default: false
      },
      serieColors: { type: Array, required: false }
    },
    mixins: [
      WidgetMixin
    ],
    computed: {
      ...mapGetters([
        'datesScope',
        'dashboardFilterUnit',
        'dashboardFilterBase64',
        'currentUser'
      ]),
      gradientColorLine() {
        return [{
          linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1
          },
          stops: this.stopColors
        }]
      },
      defaultOptions() {
        return {
          legendEnabled: this.legendEnabled,
          periodFrom: this.datesScope.dateBegin,
          periodTo: this.datesScope.dateEnd,
          request: this.groupedBasedRequest,
          tickUnit: this.dashboardFilterUnit,
          to: this.datesScope.dateEnd,
          periodNumber: (this.dashboardFilterUnit == 'month' ? 12 : 6),
          chartZoom: this.chartZoom,
          valueKey: this.indicatorAlias,
          markerEnabled: false,
          markerColor: '$colors.violet',
          max: this.avgScoreScale?.max || this.max,
          min: this.avgScoreScale?.min || this.min
        }
      },
      groupedBasedRequest() {
        return this.basedRequest.group([
          this.basedGroup,
          { campaign_name: {} }
        ])
      },
      basedGroup() {
        if (this.dashboardFilterUnit === 'day') {
          return { created_at: { mod: 'DATE_TRUNC_DAY', cast: 'date' } }
        } else if (this.dashboardFilterUnit === 'week') {
          return { created_at: { mod: 'DATE_TRUNC_WEEK', cast: 'date' } }
        } else if (this.dashboardFilterUnit === 'month') {
          return { created_at: { mod: 'DATE_TRUNC_MONTH', cast: 'date' } }
        } else if (this.dashboardFilterUnit === 'quarter') {
          return { created_at: { mod: 'DATE_TRUNC_QUARTER', cast: 'date' } }
        } else if (this.dashboardFilterUnit === 'year') {
          return { created_at: { mod: 'DATE_TRUNC_YEAR', cast: 'date' } }
        }
      },
      exportOptions() {
        return [
          { title: 'PNG', onClick: this.exportToPng },
          { title: 'PDF', onClick: this.exportToPdf }
        ]
      }
    },
    methods: {
      tooltipContent(graph) {
        let body =`
          <h1 style="font-size: 14px; font-weight: 700;">${graph.point.category}</h1>
          <br>
          <span>${graph.series.name}</span>
          <br>
          <span style="font-weight: 700; font-size: 24px;">${Number(graph.point.y).toFixed(1)}</span>
          <br>
        `
        
        if (graph.series.userOptions.rawData[graph.point.index].nbReview) {
          body += `<span>${this.$t('nb_review', { count: graph.series.userOptions.rawData[graph.point.index].nbReview })}</span>`
        }

        return `<div style="text-align: center;">${body}</div>`
      },
      exportToPng() {
        this.$refs.timelineChart.exportToPng(
          this.exportFilename,
          this.title,
          this.defaultSubtitle
        )
      },
      async exportToPdf() {
        this.$refs.timelineChart.exportToPdf(
          this.exportFilename,
          this.title,
          this.defaultSubtitle
        )
      }
    }
  }
</script>
