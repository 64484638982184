import $api from '@api'
import { legacyUrl } from '@shared/helpers/env-helpers'

const voterContext = {
  state: () => ({
    id: null,
    loaded: null,
    showActions: false,
    showedActions: false,
    actionsTab: null,
    mobileTab: null,
    isActionSubMenuOpened: false,
    isMobileActionMenuOpened: false,
    complete: null,
    collapsed: false,
    digest: null,
    uniqueId: null,
    author: null,
    createdAt: null,
    avgScore: null,
    source: null,
    insatisfactionStatus: null,
    conversationManagerId: null,
    conversation: { managerEmail: null },
    campaignId: null,
    customerId: null,
    email: null,
    nps: null,
    phoneNumber: null,
    placeName: null,
    campaignName: null,
    editingCallReport: null,
    editingInternalMemo: null,
    editingMessage: null,
    processingStatus: null,
    firstTreatmentAction: null,
    insatisfactionUserGroupName: null,
    insatisfactionUserGroupId: null,
    hasVoterAutomatedMessageTreatmentScheduled: undefined,
    insatisfactionTransferUserMail: null,
    diffusedOnTrustville: null,
    precalculatedProductStatus: null,
    trustvillePageOrWidgetEnabled: null,
    googleBrandPlace: null,
    voterThirdPartyTransfer: null,
    isRenotation: false,
    replierLabel: null,
    fullTrustvilleStates: null
  }),
  getters: {
    currentVoter(state, getters) {
      if (state.loaded) {
        return state
      }
    },
    currentVoterEditingCallReport(state, getters) {
      return state.editingCallReport
    },
    currentVoterEditingInternalMemo(state, getters) {
      return state.editingInternalMemo
    },
    currentVoterEditingMessage(state, getters) {
      return state.editingMessage
    },
    currentVoterShowActions(state, getters) {
      return state.showActions
    },
    currentVoterShowedActions(state, getters) {
      return state.showedActions
    },
    currentVoterActionsTab(state, getters) {
      return state.actionsTab
    },
    currentVoterMobileTab(state, getters) {
      return state.mobileTab
    },
    currentVoterIsMobileActionMenuOpened(state, getters) {
      return state.isMobileActionMenuOpened
    },
    currentVoterIsActionSubMenuOpened(state, getters) {
      return state.isActionSubMenuOpened
    },
    currentVoterCampaign(state, getters) {
      return getters.currentDashboardCampaignById &&
             getters.currentDashboardCampaignById[state.campaignId]
    },
    currentVoterSmartAnswersEnabled(state, getters) {
      return !!getters.currentVoterCampaign?.smartAnswersEnabled
    },
    voterConversationManagerEmail(state, getters) {
      return state.conversation?.managerEmail
    },
    voterHasNoAnswer(state, getters) {
      return state.conversation?.messages?.length < 1
    },
    currentVoterPdfExportLink(state, getters) {
      const currentDashboard = getters.currentDashboard
      const dashboardType =
        currentDashboard.type === 'DashboardBasic' ||
        currentDashboard.type === 'mono'
          ? 'mono'
          : 'multi'

      return `${legacyUrl}/dashboards/${currentDashboard.id}/${dashboardType}/customer/${state.customerId}/download_voter/${state.uniqueId}?nup=1&alk=${getters.currentUser.alk}`
    },
    voterTrustvillePublicState(state) {
      return state.fullTrustvilleStates?.publicState?.name
    },
    voterTrustvilleReportable(state, getters) {
      return ['soon_online', 'online'].includes(getters.voterTrustvillePublicState)
    },
    currentVoterVoterThirdPartyTransfer(state, getters) {
      return state.voterThirdPartyTransfer
    },
    currentVoterHasVoterAutomatedMessageTreatmentScheduled(state, getters) {
      return state.hasVoterAutomatedMessageTreatmentScheduled
    },
    fullTrustvilleStates(state) {
      return state.fullTrustvilleStates
    }
  },
  mutations: {
    setVoterContext(state, data) {
      state.id = data.id
      state.loaded = Date.now()
      state.digest = data.digest
      state.source = data.source
      state.uniqueId = data.uniqueId
      state.author = data.author
      state.firstName = data.firstName
      state.lastName = data.lastName
      state.createdAt = data.createdAt
      state.avgScore = data.avgScore
      state.email = data.email
      state.phoneNumber = data.phoneNumber
      state.insatisfactionStatus = data.insatisfactionStatus
      state.placeName = data.placeName
      state.campaignName = data.campaignName
      state.customerId = data.customerId
      state.placeId = data.placeId
      state.collapsed = false
      state.showActions = false
      state.showedActions = false
      state.campaignId = data.campaignId
      state.nps = data.nps
      state.prospectId = data.prospectId
      state.placeCampaignId = data.placeCampaignId
      state.conversation = data.conversation
      state.conversationManagerId = data.conversation?.managerId
      state.language = data.language
      state.processingStatus = data.processingStatus
      state.firstTreatmentAction = data.firstTreatmentAction
      state.insatisfactionUserGroupName = data.insatisfactionUserGroupName
      state.insatisfactionUserGroupId = data.insatisfactionUserGroupId
      state.hasVoterAutomatedMessageTreatmentScheduled = data.hasVoterAutomatedMessageTreatmentScheduled
      state.insatisfactionTransferUserMail = data.insatisfactionTransferUserMail
      state.fullTrustvilleStates = data.fullTrustvilleStates
      state.editingCallReport = null
      state.editingInternalMemo = null
      state.editingMessage = null
      state.complete = data.complete
      state.diffusedOnTrustville = data.diffusedOnTrustville,
      state.precalculatedProductStatus = data.precalculatedProductStatus
      state.trustvillePageOrWidgetEnabled = data.trustvillePageOrWidgetEnabled,
      state.googleBrandPlace = data.googleBrandPlace,
      state.voterThirdPartyTransfer = data.voterThirdPartyTransfer
      state.isRenotation = data.isRenotation
      state.replierLabel = data.replierLabel
    },
    setVoterCollapse(state, value) {
      state.collapsed = value
    },
    setActionsTab(state, value) {
      state.actionsTab = value
    },
    setMobileTab(state, value) {
      state.mobileTab = value
    },
    setIsMobileActionMenuOpened(state, value) {
      state.isMobileActionMenuOpened = value
    },
    setIsActionSubMenuOpened(state, value) {
      state.isActionSubMenuOpened = value
    },
    setShowVoterActions(state, value) {
      state.showActions = value
    },
    deleteMessage(state, value) {
      state.conversation.messages = state.conversation.messages.filter((message) => message.id !== value)
    },
    setShowedVoterActions(state, value) {
      state.showedActions = value
    },
    destroyVoterContext(state) {
      state.loaded = false
    },
    setInsatisfactionStatus(state, value) {
      state.insatisfactionStatus = value
    },
    setEditingCallReport(state, value) {
      state.editingCallReport = value
    },
    setEditingInternalMemo(state, value) {
      state.editingInternalMemo = value
    },
    setEditingMessage(state, value) {
      state.editingMessage = value
    },
    setVoterThirdPartyTransfer(state, value) {
      state.voterThirdPartyTransfer = value
    },
    setFullTrustvilleState(state, value) {
      state.fullTrustvilleStates = value
    }
  },
  actions: {
    async voterContext(context, voterUniqueId) {
      const digest = voterUniqueId

      if (digest !== context.state.digest || !context.state.loaded) {
        const ctx = await $api.wizville.contexts.authVoter(voterUniqueId)

        if (ctx?.voter) {
          context.commit('setVoterContext', { digest, ...ctx.voter })
        }
      }
    },
    voterInsatisfactionStatus(context, insatisfactionStatus) {
      context.commit('setInsatisfactionStatus', insatisfactionStatus)
    },
    collapseVoter(context) {
      context.commit('setVoterCollapse', true)
    },
    setShowVoterActions(context, value) {
      context.commit('setShowVoterActions', value)
    },
    deleteMessage(context, value) {
      context.commit('deleteMessage', value)
    },
    setShowedVoterActions(context, value) {
      context.commit('setShowedVoterActions', value)
    },
    uncollapseVoter(context) {
      context.commit('setVoterCollapse', false)
    },
    destroyVoterContext(context) {
      context.commit('destroyVoterContext')
    },
    setActionsTab(context, tab) {
      context.commit('setActionsTab', tab)
    },
    setMobileTab(context, tab) {
      context.commit('setMobileTab', tab)
    },
    setIsMobileActionMenuOpened(context, value) {
      context.commit('setIsMobileActionMenuOpened', value)
    },
    setIsActionSubMenuOpened(context, value) {
      context.commit('setIsActionSubMenuOpened', value)
    },
    setEditingCallReport(context, value) {
      context.commit('setEditingCallReport', value)
    },
    setEditingInternalMemo(context, value) {
      context.commit('setEditingInternalMemo', value)
    },
    setEditingMessage(context, value) {
      context.commit('setEditingMessage', value)
    },
    setVoterThirdPartyTransfer(context, value) {
      context.commit('setVoterThirdPartyTransfer', value)
    },
    setFullTrustvilleState(context, value) {
      context.commit('setFullTrustvilleState', value)
    }
  },
}

export default voterContext
