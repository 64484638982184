<template>
  <div class="full-width">
    <v-row no-gutters class="dashboard-stats-main-indicators-section">
      <template v-for="campaign in currentDashboardScopedCampaigns">
        <CampaignIndicatorScoreWidget
          v-if="campaign.sources.includes('wizville') && campaign.hasAvgScore"
          ref="CampaignIndicatorScoreWidget"
          :campaign="campaign"
          :campaignIndicator="null"
          :cols="3"
          :partialCampaignIndicator="currentUserCampaignScoreIndicator(campaign.id)"
          :key="$key('CampaignIndicatorScoreWidget', dashboardFilterBase64, campaign.id)"
        />
      </template>

      <ProductRecommendationRateWidget
        v-if="campaign.hasProductQuestion"
        v-for="campaign in currentDashboardScopedCampaigns"
        ref="ProductRecommendationRateWidget"
        :campaignId="campaign.id"
        :cols="3"
        :key="$key('ProductRecommendationRateWidget', dashboardFilterBase64, campaign.id)"
      />

      <NpsScoreWidget
        v-if="campaign.hasNpsQuestion"
        v-for="campaign in currentDashboardScopedCampaigns"
        ref="NpsScoreWidget"
        :campaign="campaign"
        :cols="3"
        :key="$key('NpsScoreWidget', dashboardFilterBase64, campaign.id)"
      />

      <VoterVolumePerSourceWidget
        v-if="this.hasAccessToVoterVolume"
        :key="$key('VoterVolumePerSourceWidget', dashboardFilterBase64)"
        :withLink="true"
        :cols="3"
      />

      <SocialScoreWidget
        v-for="source in currentDashboardPublicPlatforms"
        ref="SocialScoreWidget"
        :cols="3"
        :source="source"
        :key="$key('SocialScoreWidget', dashboardFilterBase64, source)"
      />

      <template v-for="campaign in currentDashboardScopedCampaigns">
        <CampaignIndicatorScoreWidget
          v-if="campaign.hasAvgScore"
          v-for="campaignIndicator in campaign.campaignIndicators"
          ref="CampaignIndicatorScoreWidget"
          :campaign="campaign"
          :campaignIndicator="null"
          :cols="3"
          :partialCampaignIndicator="campaignIndicator"
          :key="$key('CampaignIndicatorScoreWidget', dashboardFilterBase64, campaignIndicator.id)"
        />
      </template>

      <LrmBenchmarkWidget
        v-if="currentDashboard.preferences.isLrmEligible"
        ref="LrmBenchmarkWidget"
        :footerSpacer="!hasAccessToAllDashboardPlaces"
      />

      <template v-for="campaign in currentDashboardScopedCampaigns">
        <FeedbackProcessingTimeWidget
          v-if="campaign.hasAvgScore && shouldDisplayInsatisfactionWidget"
          ref="FeedbackProcessingTimeWidget"
          :campaign="campaign"
          :footerSpacer="!hasAccessToAllDashboardPlaces"
          :key="$key('FeedbackProcessingTimeWidget', dashboardFilterBase64, campaign.id)"
        />
      </template>
    </v-row>

    <v-row no-gutters>
      <template v-for="campaign in currentDashboardScopedCampaigns">
        <template
          v-if="
            campaign.hasAvgScore &&
            campaign.wizville && 
            currentTopicsByCampaignId[campaign.id]?.length > 0
          "
        >
          <template>
            <WStatsWidget
              v-if="topicsMixin_topics[campaign.id] === undefined"
              :cols="6"
              :empty="false"
              :load="loadData()"
              :loading="true"
              :title="$t('statistics_widget_topics_ranking_title')"
              :key="$key('topicsMixin_loadTopics', dashboardFilterBase64, campaign.id)"

            />
            <TopicsTableWidget
              v-else
              ref="TopicsTableWidget"
              :campaign="campaign"
              :compact="true"
              :showExportOptions="false"
              :topics="topicsMixin_topics[campaign.id]"
              :key="$key('TopicsTableWidget', dashboardFilterBase64, campaign.id)"
            />
          </template>
        </template>
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TopicsTableWidget from '@statistics/TopicsPage/TopicsTableWidget'
import NpsScoreWidget from '@statistics/NpsPage/NpsZoom/NpsScoreWidget'
import FeedbackProcessingTimeWidget from '@statistics/FeedbackTreatmentPage/FeedbackTreatmentZoom/FeedbackProcessingTimeWidget'
import LrmBenchmarkWidget from './LrmBenchmarkWidget'
import SocialScoreWidget from '@statistics/SocialPage/SourceZoom/SocialScoreWidget'
import CampaignIndicatorScoreWidget from '@statistics/SatisfactionPage/CampaignIndicatorZoom/CampaignIndicatorScoreWidget'
import NisMixin from '@statistics/shared/nis_mixin'
import TopicsMixin from '@statistics/shared/topics_mixin'
import ProductRecommendationRateWidget from '@statistics/ProductsPage/ProductsZoom/ProductRecommendationRateWidget'
import VoterVolumePerSourceWidget from '@statistics/VoterVolumePage/VoterVolumePerSourceWidget.vue'

export default {
  name: 'DashboardStatsMainIndicatorsSection',
  components: {
    NpsScoreWidget,
    TopicsTableWidget,
    FeedbackProcessingTimeWidget,
    SocialScoreWidget,
    VoterVolumePerSourceWidget,
    LrmBenchmarkWidget,
    CampaignIndicatorScoreWidget,
    ProductRecommendationRateWidget
  },
  mixins: [
    NisMixin,
    TopicsMixin,
  ],
  mounted() {
    const areIndicatorsMounted =
      !!(Object.keys(this.$refs).find(
        ref => [
          "NpsScoreWidget",
          "SocialScoreWidget",
          "CampaignIndicatorScoreWidget",
          "FeedbackProcessingTimeWidget",
          "TopicsTableWidget"
        ].includes(ref)
      ))
    this.$emit('onIndicatorsMounted', areIndicatorsMounted);
  },
  methods: {
    loadData() {
      for (const campaign of this.currentDashboardScopedCampaigns) {
        this.topicsMixin_loadTopics(campaign)
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentDashboard',
      'currentDashboardInsatisfactionNotificationEnabled',
      'currentDashboardPublicPlatforms',
      'currentDashboardScopedCampaigns',
      'currentTopicsByCampaignId',
      'currentUserCampaignScoreIndicator',
      'hasAccessToAllDashboardPlaces',
      'hasAccessToVoterVolume',
      'dashboardFilterBase64'
    ]),
    shouldDisplayInsatisfactionWidget() {
      return this.currentDashboardInsatisfactionNotificationEnabled
    }
  }
}
</script>
