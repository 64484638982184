<template>
  <div class="statistics">
    <StatisticsNavigation
      class="statistics-navigation"
      :class="navigationClass"
      :dashboard-stats-pages="dashboardStatsPages"
    />

    <EmptyPage
      v-if="
        sectionType == 'zoom' &&
        ['satisfaction', 'processing', 'verbatim'].includes(sectionId)
      "
    />
    <DashboardStatsPage
      v-if="sectionType == 'summary' && dashboardStatsPage"
      :dashboard-stats-page="dashboardStatsPage"
    />
    <SatisfactionPage
      v-if="sectionType == 'satisfaction'"
      :campaignId="computedSubSectionId"
      :selectedCampaignIndicatorId="computedSectionId"
    />
    <NpsPage
      v-if="sectionType == 'nps'"
      :selectedCampaignId="computedSubSectionId"
    />
    <SocialPage
      v-if="sectionType == 'social'"
      :source="sectionId"
    />
    <TopicsPage
      v-if="sectionType == 'topics'"
      :campaignId="Number(computedSubSectionId)"
      :selectedTopicId="computedSectionId"
    />
    <NisPage
      v-if="sectionType == 'nis'"
      :campaignId="Number(computedSubSectionId)"
      :selectedTopicId="computedSectionId"
    />
    <FeedbackTreatmentPage
      v-if="sectionType == 'feedbackTreatment'"
      :campaignId="Number(computedSubSectionId)"
    />
    <QuestionsPage
      v-if="sectionType == 'questions'"
      :questionId="sectionId == 'all' ? null : Number(sectionId)"
      :campaignId="Number(computedSubSectionId)"
    />
    <VerbatimPage
      v-if="sectionType == 'verbatim' && hasAccessToNewPlatformStatisticVerbatimAnalysis"
      :campaignId="Number(computedSubSectionId)"
      :themeOrTopic="sectionId || 'all'"
      :key="dashboardFilterBase64"
    />
    <VoterVolumePage
      v-if="sectionType == 'voterVolume'"
    />
  </div>
</template>

<script>
  import StatisticsNavigation from './StatisticsNavigation'
  import DashboardStatsPage from './DashboardStatsPage'
  import NpsPage from './NpsPage'
  import SocialPage from './SocialPage'
  import NisPage from './NisPage'
  import SatisfactionPage from './SatisfactionPage'
  import EmptyPage from './EmptyPage'
  import TopicsPage from './TopicsPage'
  import QuestionsPage from './QuestionsPage'
  import VerbatimPage from './VerbatimPage'
  import FeedbackTreatmentPage from './FeedbackTreatmentPage'
  import VoterVolumePage from './VoterVolumePage'
  import { mapGetters } from 'vuex'

  export default {
    name: "Statistics",
    components: {
      StatisticsNavigation,
      DashboardStatsPage,
      NpsPage,
      SocialPage,
      NisPage,
      SatisfactionPage,
      EmptyPage,
      VerbatimPage,
      TopicsPage,
      QuestionsPage,
      FeedbackTreatmentPage,
      VoterVolumePage
    },
    props: {
      sectionType: { type: String, required: true },
      sectionId: { type: Number | String },
      subSectionId: { type: Number | String },
    },
    computed: {
      ...mapGetters([
        'currentDashboardRequest',
        'currentDashboardBasedTable',
        'currentDashboardCampaignWithId',
        'dashboardFilterBase64',
        'hasAccessToNewPlatformStatisticVerbatimAnalysis',
        'sidebarMinimized'
      ]),
      navigationClass() {
        return {
          'width-sidebar-mini': this.sidebarMinimized && this.$vuetify.breakpoint.smAndUp,
          'width-sidebar-maxi': !this.sidebarMinimized && this.$vuetify.breakpoint.smAndUp
        }
      },
      dashboardStatsPage() {
        return this.dashboardStatsPages[Number(this.sectionId) - 1] || null
      },
      dashboardStatsPages() {
        return (this.dashboardStatsTemplate?.dashboardStatsPages?.sort((a, b) => (a.position - b.position)) || [])
      },
      computedSectionId() {
        return this.sectionId && this.sectionId !== 'all' ? this.sectionId : null
      },
      computedSubSectionId() {
        return this.subSectionId ? this.subSectionId : null
      },
    },
    asyncComputed: {
      dashboardStatsTemplate: {
        async get() {
          const request = this.currentDashboardRequest.select({
            [this.currentDashboardBasedTable]: [ 'dashboard_stats_template' ]
          })
          return (await this.$resolve(request)).first().dashboardStatsTemplate
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~@theme/constants.styl'

  .statistics
    padding-top: "calc(%s + %s)" % ($header-menu-height $filters-height)

    .statistics-navigation
      transition: width 250ms
      position: fixed
      top: "calc(%s + %s + 1px)" % ($header-menu-height $filters-height)
      z-index: 5
      &.width-sidebar-mini
        width: "calc(100% - %s)" % $sidebar-mini-width
      &.width-sidebar-maxi
        width: "calc(100% - %s)" % $sidebar-width
</style>
