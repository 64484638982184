<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <div>
          <WCode id="code" v-model="pdfContent" :key="resetCounter" :readonly="readonly" />
        </div>

        <div>
          <v-btn icon color="error" @click="resetPdf()" v-if="!readonly">
            <v-icon>
              mdi-restart-alert
            </v-icon>
          </v-btn>

          <v-btn icon color="success" :loading="generateLoading" @click="generatePdf(pdfContent)">
            <v-icon>
              mdi-send
            </v-icon>
          </v-btn>

          <v-btn icon color="info" @click="savePdf">
            <v-icon>
              mdi-download
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <div v-if="pdfData" id="wrapper">
      <object :data="pdfData" id="output" type="application/pdf">
      </object>
    </div>
  </div>
</template>

<script>
  import _debounce from "lodash/debounce"
  import removeExtraIndentation from '@shared/helpers/indent'
  import Pdf from '@shared/helpers/exportToPdf/pdf'
  import ExportingHighcharts from "highcharts/modules/exporting"
  import Highcharts from "highcharts"

  ExportingHighcharts(Highcharts)

  export default{
    name: "WPdfSandbox",
    props: {
      content: { type: String, required: false, default: "" } ,
      readonly: { type: Boolean, required: false, default: false }
    },
    data() {
      return {
        defaultPdfContent: this.content,
        pdfContent: this.content,
        pdfData: null,
        generateLoading: false,
        exportLoading: false,
        resetCounter: 0,
        result: "",
        status: null
      }
    },
    computed: {},
    methods: {
      autoIndentPdfContent() {
        if (this.pdfContent) {
          this.pdfContent = removeExtraIndentation(this.pdfContent).trim()
        }
      },
      refreshPdf() {
        this.defaultPdfContent = this.content;
        this.pdfContent = this.content;
        this.autoIndentPdfContent();
        this.generatePdf(this.pdfContent);
      },
      savePdf() {
        this.result.doc.save();
      },
      generatePdf: _debounce(async function(content) {

        this.generateLoading = true;

        try {
          let AsyncFunction = Object.getPrototypeOf(async function(){}).constructor
          let sandbox = new AsyncFunction('Pdf', 'Highcharts', `try {
            return (async function() {
              ${content}
              return pdf;
            }());
          } catch(error) {
            console.log(error);
            return ({ message: "js syntax error: " + error.message, status: "ko" })
          }`)

          this.result = await sandbox(Pdf, Highcharts);
          this.pdfData = this.result.doc.output('datauristring');
        } catch (error) {
          console.log(error);
          this.result = { status: "ko", message: `js syntax error: ${error.message}` }
        }
        this.generateLoading = false
      }, 1000, { leading: true }),

      resetPdf() {
        this.pdfContent = this.defaultPdfContent;
        this.generatePdf(this.pdfContent);
        this.autoIndentPdfContent();
        this.resetCounter += 1
      },
    },
    watch: {
      content: function() {
        this.refreshPdf();
      }
    },
    mounted() {
      this.refreshPdf();
    },
  }
</script>

<style lang="stylus" scoped>
  #output
    width: 100%;
    height: 1000px;
</style>
