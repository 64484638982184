const COLORS_BY_SOURCE = {
    'avis_verifies': '#6DBBB6', // Neptune
    'bazaar_voice': '#8BBB99', // Bay Leaf
    'deliveroo': '#9FBAB8', // Shadow Green
    'facebook': '#2E1C4D', // Danube
    'yotpo': '#C86868', // Chetnut Rose
    'mailing': '#FFB800', //
    'mono': '#E6DF8D', // Flax
    'normal': '#4EBDB7', // Antique Brass
    'pages_jaunes': '#FFD333', // Bright Sun 500
    'pollster': '#CEF2F0', // Sea Green 300
    'pull_web': '#9A8C5A', // Barley
    'push_web': '#9AA25F', // Green Smoke
    'qrcode': '#D3AB60', // Harvest Gold
    'skeepers': '#B27B59', // Wisteria
    'sms': '#DB3572', // Indigo
    'sms_pull': '#66ACDF', // Jordy Blue
    'trip_advisor': '#AE6B84', // Bouquet
    'ubereats': '#A6BB50', // Celery 500
    'url': '#95AD86', // Grey Chateau
    'web': '#88C788', // Eastern Blue
    'google': '#BA0022', //
}

const SOURCE_MAPPING = {
    'pull_web': 'web',
    'push_web': 'web',
    'sms_pull': 'sms',
    'null': 'normal'
}

export { COLORS_BY_SOURCE, SOURCE_MAPPING }