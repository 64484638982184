<template>
  <v-card
    :flat="$vuetify.breakpoint.xsOnly"
    class="voter-list">
    <VoterStatusNav />
    <div
      class="header-row d-flex align-center"
      v-if="$vuetify.breakpoint.smAndUp"
    >
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        hide-details
        solo
        flat
        clearable
        class="pt-0 mt-0 search-input"
        :background-color="searchFocus || search ? '#EDF1F7' : ''"
        height="48px"
        @focus="searchFocus = true"
        @blur="setLocalFiltersSearch(search)"
        @keypress.enter="setLocalFiltersSearch(search)"
        @click:clear="setLocalFiltersSearch('')"
      />

      <VoterScoreFilter
        v-model='localFilters.scoreFilters'
        v-if="status === 'all'" style="text-align: right"
      />

      <treat-filters-header
        v-if="status === 'treat'"
        v-model='localFilters.treatFilters'
        :subStatus="subStatus"
      />

      <v-divider
        vertical
      />

      <div 
        v-if="currentAdmin"
        style="border-left: 1px solid #d3d3d3 !important; border-right: 1px solid #d3d3d3 !important;" 
        class="px-3 cursor-pointer"
      >
        <WIcon
          :icon-style="{fontSize: '24px'}"
          icon="auto-fix-gradient"
          @click="headerExpanded = !headerExpanded"
        />
      </div>


      <w-drop-down-menu
        v-model="optionsMenuSelectedValues"
        :items="optionsMenuItems"
        :icon="optionsMenuSelectedIcon"
        :showGroupTitle="showTreatVotersPeriodSelect"
      >
      </w-drop-down-menu>
    </div>

    <div style="height: 600px; overflow-y: scroll" v-show="headerExpanded">
      <AdvancedSearch
        :filteredVoterRequestBase="filteredVoterRequestBase"
       />
    </div>

    <v-data-table
      :headers="headers"
      :items="voters"
      :options.sync="options"
      :items-per-page="30"
      :server-items-length="voterTotal"
      :loading="loading"
      :loading-text="$t('loading_text')"
      :no-data-text="$t('no_data_text')"
      :no-results-text="$t('no_results_text')"
      class="voters-data-table data-table"
      hide-default-header
      :height="tableHeight"
    >
      <template #item="{ item }">
        <tr :class="[{ 'active': item.uniqueId === activeVoterUniqueId && $vuetify.breakpoint.mdAndUp }]">
          <td>
            <div @click="goToVoter(item.uniqueId)">
              <VoterItem :voter="item" :isActive="item.uniqueId === activeVoterUniqueId && $vuetify.breakpoint.mdAndUp" />
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:footer.prepend>
        <v-btn icon :disabled="loading" :loading="exporting" @click="exportVoters" v-if="currentAdmin">
          <v-icon small>
            mdi-export
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _isEqual from 'lodash/isEqual'
  import VoterItem from "./VoterItem"
  import VoterScoreFilter from "./VoterScoreFilter"
  import VoterStatusNav from './VoterStatusNav'
  import AdvancedSearch from './AdvancedSearch'
  import TreatFiltersHeader from './TreatFiltersHeader'
  import VoterRequestsMixin from "../../shared/voter_requests_mixin"
  import { removeQueryParamWithoutReload } from '@shared/helpers/query'

  export default {
    name: "VoterList",
    components: {
      VoterItem,
      TreatFiltersHeader,
      VoterScoreFilter,
      VoterStatusNav,
      AdvancedSearch
    },
    mixins: [
      VoterRequestsMixin,
    ],
    props: {
      status: {
        type: String,
        default: 'all'
      },
      subStatus: {
        type: String,
        default: 'all'
      }
    },
    data () {
      return {
        goToParamsVoter: true,
        headerExpanded: false,
        loading: true,
        exporting: false,
        options: {},
        search: null,
        searchFocus: false,
        localFilters: {
          treatFilters: {},
          sortFilters: { sortValue: 'created_at', order: 'desc', id: 'sorting' },
          scoreFilters: { mainScoreFilter: null },
          searchFilter: { value: "" }
        },
        headers: [
          {
            text: 'Voters',
            align: 'start',
            sortable: false,
            value: 'voter',
          }
        ],
        voterTotal: null,
        activeVoterUniqueId: null,
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboardRankingRelatedColumn',
        'currentDashboard',
        'dashboardFilterRequest',
        'dashboardFilterReady',
        'currentDashboardParams',
        'dashboardAvgScale',
        'dashboardFilterBase64',
        'currentVoter',
        'currentUser',
        'currentAdmin',
        'dashboardFilterDatesText',
        'currentDashboardTreatVotersPeriod',
        'currentDashboardTrustvillePublicStateFilter',
        'isCurrentDashboardTrustvilleEligible',
        'currentDashboardInsatisfactionNotificationEnabled',
        'datesScope'
      ]),
      optionsMenuSelectedIcon() {
        const item = this.optionsMenuItems[0].children.find((item) => item.value.id == this.optionsMenuSelectedValues[0].id)

        return item.icon
      },
      optionsMenuSelectedValues: {
        get() {
          return [
            this.localFilters.sortFilters,
            this.currentDashboardTreatVotersPeriod,
            this.currentDashboardTrustvillePublicStateFilter
          ]
        },
        set(newValues) {
          Object.values(newValues).forEach(newValue => {
            if (newValue?.id === 'sorting') {
              this.localFilters.sortFilters = newValue
            } else if (newValue?.id === 'trustville_public_status') {
              this.$store.dispatch('setTrustvillePublicStateFilter', newValue.value)
            } else if (newValue?.id === 'sharing_date') {
              this.$store.dispatch('setTreatVotersPeriod', newValue.value)
            }
          })
        }
      },
      optionsMenuItems() {
        const items =  [
          {
            title: this.$t('sorting'),
            children: [
              {
                title: this.$t('sort_filter_newer'),
                icon: 'mdi-sort-clock-ascending-outline',
                value: { sortValue: 'created_at', order: 'desc', id: 'sorting' }
              },
              {
                title: this.$t('sort_filter_older'),
                icon: 'mdi-sort-clock-descending-outline',
                value: { sortValue: 'created_at', order: 'asc', id: 'sorting' }
              },
              {
                title: this.$t('sort_filter_most_negative'),
                icon: 'mdi-sort-numeric-descending',
                value: { sortValue: this.currentDashboardRankingRelatedColumn, order: 'asc', id: 'sorting' }
              },
              {
                title: this.$t('sort_filter_most_positive'),
                icon: 'mdi-sort-numeric-ascending',
                value: { sortValue: this.currentDashboardRankingRelatedColumn, order: 'desc', id: 'sorting' }
              }
            ]
          },
        ]

        if (this.isCurrentDashboardTrustvilleEligible) {
          items.push(
            {
              title: this.$t('Trustville'),
              children: [
                {
                  title: this.$t('trustville_status_online_all'),
                  value: { value: null, id: 'trustville_public_status' }
                },
                {
                  title: this.$t('trustville_status_online'),
                  value: { value: "online", id: 'trustville_public_status' }
                },
                {
                  title: this.$t('trustville_status_not_online'),
                  value: { value: 'unpublished', id: 'trustville_public_status' }
                },
                {
                  title: this.$t('trustville_status_soon_online'),
                  value: { value: "soon_online", id: 'trustville_public_status' }
                },
                {
                  title: this.$t('trustville_status_under_moderation'),
                  value: { value: "under_moderation", id: 'trustville_public_status' }
                }
              ]
            }
          )
        }

        if (this.showTreatVotersPeriodSelect) {
          items.push(
            {
              title: this.$t('sharing_date'),
              children: [
                {
                  title: this.$t('dateFilter.genesis'),
                  value: { value: "genesis", id: 'sharing_date' }
                },
                {
                  title: this.dashboardFilterDatesText,
                  value: { value: "custom", id: 'sharing_date' }
                }
              ]
            }
          )
        }

        return items
      },
      showTreatVotersPeriodSelect() {
        return this.currentDashboardInsatisfactionNotificationEnabled && this.datesScope.key !== 'genesis'
      },
      tableHeight() {
        const headerExpandedHeight = (this.headerExpanded ? 600 : 0)
        return this.$vuetify.breakpoint.xsOnly ?
          `calc(100vh - ${192 + headerExpandedHeight}px)` :
          `calc(100vh - ${240 + headerExpandedHeight}px)`
      }
    },
    asyncComputed: {
      voters: {
        async get() {
          this.loading = true

          if (this.options.page && this.dashboardFilterReady) {
            const { voterData } = await this.getVotersFromApi()
            this.setVoterTotal()

            if (!this.currentVoter) {
              this.activeVoterUniqueId = null
            }
            if (this.$route.name === "Feedbacks") {
              if (voterData.length === 0) {
                this.options.page = 1
                removeQueryParamWithoutReload(this.$route, 'voter_unique_id')
                await this.$store.dispatch("destroyVoterContext")
              } else {
                let voterUniqueId = this.goToParamsVoter &&
                  (this.$vuetify.breakpoint.smAndDown ?
                    this.$route?.params?.voterUniqueId :
                    this.$route?.params?.voterUniqueId || voterData[0]?.uniqueId)
                if (voterUniqueId) {
                  this.goToVoter(
                    voterUniqueId,
                    false
                  )
                }
              }

              this.goToParamsVoter = false

              this.scrollTopTable()
            }

            this.loading = false
            return voterData
          } else {
            return []
          }
        },
        default: [],
        watch: ['options', 'currentDashboardTreatVotersPeriod', 'currentDashboardPublicStateFilter', 'status', 'subStatus', 'localFilters']
      }
    },
    methods: {
      async exportVoters() {
        this.exporting = true
        const mainIndicatorSource = [ "treat", "treated" ].includes(this.$route.params.status) ? 'insatisfactions' : 'satisfactions'

        const voterUniqueIds = this.voters.map(voter => voter.uniqueId)
        const request = this.$basedRequest().select({
          voters: [ { voter_in_email_pdf: { batch_params: { main_indicator_source: mainIndicatorSource } } }, { unique_id: { as: 'uniqueId' } } ]
        }).where({
          unique_id: voterUniqueIds
        })
        
        const votersInEmailByUniqueId = (await this.$resolve(request, 'exportVoters'))?.data?.voters?.reduce((h, voter) => {
          h[voter.uniqueId] = voter.voterInEmailPdf
          return h
        }, {})

        const votersInEmail = voterUniqueIds.map(uniqueId => {
          return votersInEmailByUniqueId[uniqueId]
        }).join("\n")

        const formatedVotersInEmail = `<div style="width: 880px; min-height: 29cm; padding: 20px; font-family: Arial, sans-serif;">${votersInEmail}</div>`
        this.$htmlToPdf(formatedVotersInEmail, `export_voters_by_${this.currentUser.mail}.pdf`)

        this.exporting = false
      },
      goToVoter(uniqueId, pushState = true) {
        this.activeVoterUniqueId = uniqueId
        if (uniqueId !== this.currentVoter?.uniqueId) {
          if (this.$route.params?.voterUniqueId !== uniqueId) {
            const target = {
              params: {
                voterUniqueId: uniqueId,
                section: 'review'
              },
              query: {
                dashboard_filters: this.dashboardFilterBase64
              }
            }

            this.$store.dispatch('collapseVoter')

            if (pushState) {
              this.$router.push(target)
            } else {
              this.$router.replace(target)
            }
          }

          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        } else {
          this.$store.dispatch("uncollapseVoter")
        }
      },
      async setVoterTotal() {
        const total = await this.getCount(this.status, this.subStatus, this.localFilters.treatFilters, this.localFilters.scoreFilters)

        this.voterTotal = total
      },
      setLocalFiltersSearch(search) {
        this.searchFocus = false
        if (search?.length == 0 || search?.length > 2) {
          this.localFilters.searchFilter.value = search
        }
      },
      scrollTopTable() {
        this.$vuetify.goTo(
          '.data-table tbody tr',
          {
            offset: 50,
            container: '.v-data-table__wrapper'
          }
        )
      }
    },
    watch: {
      '$route.params.voterUniqueId': {
        handler() {
          this.activeVoterUniqueId = this.$route.params?.voterUniqueId
        }
      }
    }
  }
</script>

<style lang="stylus">
  @import '~@theme/medias.styl'

  +forBreakpoints(mobile)
    .voters-data-table
      tbody
        display: block !important

        td
          width: 100vw

  .voter-list
    .search-input
      .v-input__slot
        border-bottom-left-radius: 0px !important
        border-bottom-right-radius: 0px !important
        border-top-right-radius: 0px !important

    .br-1
      border-right: 1px solid #d3d3d3

    .header-row
      min-height: 45px
      margin: 0!important
      border-bottom: 1px solid lightgrey
      .col
        padding: 0!important

    tr.active
      td:first-of-type
        border-left: 2px solid #087CA7
        background-color: #EBF5F9

    .v-data-table__wrapper
      background:
        linear-gradient(white 30%, rgba(255,255,255,0)),
        linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
        radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)),
        radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
      background:
        linear-gradient(white 30%, rgba(255,255,255,0)),
        linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
      background-repeat: no-repeat;
      background-color: white;
      background-size: 100% 40px, 100% 40px, 100% 6px, 100% 6px;
      background-attachment: local, local, scroll, scroll;

    .v-data-footer
      padding-left: 14px
      padding-right: 14px
      padding-top: 4px
      padding-bottom: 4px
      display: flex
      align-items: center
      flex-wrap: nowrap

      .v-data-footer__select
        display: none
      .v-data-footer__pagination
        display: none
      .v-data-footer__icons-before
        margin-left: auto
</style>
